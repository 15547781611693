import { LOCATION_WORK, GLOBAL_SETTING, USER } from '../api'
import { METHOD, request } from '@/utils/request'

// 获取租户工作地点列表
export async function getList(params) {
  return request(`${LOCATION_WORK}/workAddress/getList`, METHOD.GET, params)
}

// 新增租户工作地点
export async function createWorkAddress(params) {
  return request(`${LOCATION_WORK}/workAddress/createWorkAddress`, METHOD.POST, params)
}

// 修改租户工作地点
export async function updateWorkAddress(params) {
  return request(`${LOCATION_WORK}/workAddress/updateWorkAddress`, METHOD.POST, params)
}

// 删除租户工作地点
export async function deleteWorkAddress(params) {
  return request(`${LOCATION_WORK}/workAddress/deleteWorkAddress`, METHOD.POST, params)
}

// 操作记录日志列表-登录日志
export async function operateRecord(params) {
  return request(`${GLOBAL_SETTING}/operateRecord/page`, METHOD.GET, params)
}
// 操作日志列表查询-业务日志
export async function getRecordList(params) {
  return request(`${USER}/operateRecord/getRecordList`, METHOD.GET, params)
}
